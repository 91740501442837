import { MediaMatcher } from "@angular/cdk/layout";
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
  computed,
  inject,
  signal,
} from "@angular/core";
import { Usuario } from "src/app/core/model";
import { PrivateService } from "../private.service";
import { OAuthService } from "angular-oauth2-oidc";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { MatDividerModule } from "@angular/material/divider";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgIcon } from "@ng-icons/core";
import { MatButtonModule } from "@angular/material/button";
import { NgIf, NgClass, NgFor, DatePipe, JsonPipe } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzModalService } from "ng-zorro-antd/modal";
import { AbYoutubeVideoComponent } from "src/app/core/components/ab-youtube-video/ab-youtube-video.component";
import { UsuarioService } from "src/app/usuarios/usuario/data-access/usuario.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { FUNCIONALIDADES } from "src/app/auth/auth.constants";
import { TokenStore } from "src/app/auth/token.store";
import { AuditoriaService } from "src/app/auditoria/data-access/auditoria.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  switchMap,
} from "rxjs";
import { UltimosLogins } from "src/app/auditoria/data-access/auditoria.model";
import { NzTagModule } from "ng-zorro-antd/tag";

@Component({
  selector: "app-meus-dados",
  standalone: true,
  templateUrl: "./meus-dados.component.html",
  styles: ``,
  imports: [
    DatePipe,

    NzButtonModule,
    NzDividerModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzTagModule,

    JsonPipe,

    NgIf,
    NgClass,
    MatButtonModule,
    NgIcon,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    MatDividerModule,
    NzWaveModule,
    MatIconModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
  ],
  providers: [NzModalService],
})
export class MeusDadosComponent implements OnInit {
  private readonly tokenStore = inject(TokenStore, { skipSelf: true });
  private readonly auditoriaService = inject(AuditoriaService);
  private messageService = inject(NzMessageService);

  usuarioService = inject(UsuarioService);
  modal = inject(NzModalService);
  viewContainerRef = inject(ViewContainerRef);

  $ultimosLogins = signal<UltimosLogins[]>([]);

  carregando = false;
  salvando = false;
  editarNomeCompleto = false;
  editarNomeGuerra = false;
  editarOrgao = false;
  editarTelefone = false;
  editarMatricula = false;
  editarCargo = false;
  editarUf = false;
  editarCpf = false;
  editarEmail = false;
  editarIdTelegram = false;

  ufs = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MS",
    "MT",
    "MG",
    "PA",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SE",
    "SC",
    "SP",
    "TO",
  ];

  usuario: Usuario;
  usuarioOriginal: Usuario;

  penultimoLogin = computed(() => this.tokenStore.penultimoLogin());

  claims;

  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;

  constructor(
    private oauthService: OAuthService,
    private privateService: PrivateService,

    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  async ngOnInit(): Promise<void> {
    this.carregando = true;
    this.privateService.getMeusDados().subscribe((resp) => {
      this.carregando = false;
      this.usuario = resp;
      this.usuarioOriginal = JSON.parse(JSON.stringify(this.usuario));
    });

    await this.getUltimosLogins();
  }

  async getUltimosLogins() {
    try {
      const ultimosLogins = await firstValueFrom(
        this.auditoriaService.getUltimosLogins(),
      );
      const sortedUltimosLogins = ultimosLogins.sort(
        (a, b) => new Date(b.data).getTime() - new Date(a.data).getTime(),
      );
      this.$ultimosLogins.set(sortedUltimosLogins);
    } catch {
      this.messageService.error("Não foi possível carregar os últimos logins.");
    }
  }

  editarCampo(campo: string) {
    this.editarNomeCompleto = campo === "nomeCompleto";
    this.editarNomeGuerra = campo === "nomeGuerra";
    this.editarOrgao = campo === "orgao";
    this.editarTelefone = campo === "celular";
    this.editarMatricula = campo === "matricula";
    this.editarUf = campo === "uf";
    this.editarCargo = campo === "cargo";
    this.editarCpf = campo === "cpf";
    this.editarEmail = campo === "email";
    this.editarIdTelegram = campo === "idTelegram";
  }

  abrirTelegramHelp() {
    this.modal.create<AbYoutubeVideoComponent>({
      nzTitle: "Configure seu id Telegram",
      nzContent: AbYoutubeVideoComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzWidth: "80%",
      nzData:
        "https://www.youtube.com/embed/X5hugsDvZ6U?autoplay=1&mute=0&loop=1&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&volume=80",
    });
  }

  salvarCampo(campo: string) {
    const valorOriginal = this.usuarioOriginal[campo];
    const novoValor =
      campo === "orgao"
        ? this.usuario.usuarioOrgaoPadrao.orgao.id
        : this.usuario[campo];
    this.usuarioOriginal[campo] = "SALVANDO ...";
    this.editarCampo("nenhum");

    this.privateService.alterarMeusDados(campo, novoValor).subscribe({
      next: (resp) => {
        this.usuarioOriginal = JSON.parse(JSON.stringify(resp));
        this.usuario = JSON.parse(JSON.stringify(resp));
      },
      error: () => {
        this.messageService.error("Erro ao alterar seu dado");
        this.usuarioOriginal[campo] = valorOriginal;
      },
    });
  }

  get houveAlteracao() {
    return true;
  }

  get podeAlterarDadosImutaveis() {
    return this.tokenStore.hasRole(
      FUNCIONALIDADES.USUARIO.EDITAR_DADOS_IMUTAVEIS,
    );
  }

  get souPrf() {
    return this.usuarioOriginal.usuarioOrgaoPadrao.idOrgao === 1;
  }

  public atualizarDadosUsuario() {
    this.claims = this.oauthService.getIdentityClaims();
    this.claims.token = this.oauthService.getAccessToken();
    this.carregando = true;
    this.usuarioService.atualizarUsuario(this.claims).subscribe({
      next: (resp) => {
        this.carregando = false;
        this.usuario = resp;
        this.usuarioOriginal = JSON.parse(JSON.stringify(this.usuario));
      },
    });
  }
}
